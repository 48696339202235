import { createSlice } from "@reduxjs/toolkit";
import { getHedgeMAPListing, downloadHedgeMAP } from "./hedgeMAPsActions";

const initialState = {
  hedgeMAPListing: {
    hedgeMAPs: [] as any[],
    isError: false,
    message: "",
    isLoading: false,
  },
  downloadHedgeMAP: {
    isError: false,
    message: "",
    isLoading: false,
    fileName: "",
  },
};

export const hedgeMAPsSlice = createSlice({
  name: "hedgeMAPs",
  initialState,

  reducers: {
    resetDownloadHedgeMAP: (state) => {
      state.downloadHedgeMAP = initialState.downloadHedgeMAP;
    },
  },
  extraReducers: (builder) => {
    // Get HedgeMAP Listing`
    builder
      .addCase(getHedgeMAPListing.fulfilled, (state, action) => {
        state.hedgeMAPListing.hedgeMAPs = action.payload;
        state.hedgeMAPListing.isError = false;
        state.hedgeMAPListing.message =
          "HedgeMAP Listing fetched successfully.";
        state.hedgeMAPListing.isLoading = false;
      })
      .addCase(getHedgeMAPListing.pending, (state, action) => {
        state.hedgeMAPListing.isError = false;
        state.hedgeMAPListing.message = "Loading...";
        state.hedgeMAPListing.isLoading = true;
      })
      .addCase(getHedgeMAPListing.rejected, (state, action) => {
        state.hedgeMAPListing.isError = true;
        state.hedgeMAPListing.message = action.payload as string;
        state.hedgeMAPListing.isLoading = false;
      });

    // Download HedgeMAP
    builder
      .addCase(downloadHedgeMAP.fulfilled, (state, action: any) => {
        // let regexp = /filename=(.*?)$/;
        // let saving_file_name =
        //   action.payload.headers["content-disposition"].match(regexp)[1];

        // const fileURL = window.URL.createObjectURL(action.payload.data);

        // let alink = document.createElement("a");
        // alink.href = fileURL;
        // alink.download = saving_file_name;
        // alink.click();

        state.downloadHedgeMAP.isError = false;
        state.downloadHedgeMAP.message = "HedgeMAP downloaded successfully.";
        state.downloadHedgeMAP.isLoading = false;
        state.downloadHedgeMAP.fileName = "";
      })
      .addCase(downloadHedgeMAP.pending, (state, action) => {
        state.downloadHedgeMAP.isError = false;
        state.downloadHedgeMAP.message = "Loading...";
        state.downloadHedgeMAP.isLoading = true;
        state.downloadHedgeMAP.fileName = action.meta.arg[3];
      })
      .addCase(downloadHedgeMAP.rejected, (state, action) => {
        state.downloadHedgeMAP.isError = true;
        state.downloadHedgeMAP.message = action.payload as string;
        state.downloadHedgeMAP.isLoading = false;
      });
  },
});

export const { resetDownloadHedgeMAP } = hedgeMAPsSlice.actions;

export default hedgeMAPsSlice.reducer;
