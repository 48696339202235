// 'use strict';
import * as branding from "./branding";
import * as config from "./config-deploy";
// import { Level, Options } from './services/log.service';

// let _log_options: Options = {
//   level: Level.DEBUG,
//   // timestamp: false,
//   debug: (args: any[]) => {
//     // your custom logic, for example send log to server
//   },
//   log: undefined,
//   info: undefined,
//   error: undefined,
//   warning: undefined,
//   all: undefined,
//   };

// if (config.log_options) {
//   _log_options = config.log_options;
// }

// const log_options: Options = _log_options;

export const __version__ = 20211008;

let _deployfor: any = null;
if (config.deployfor) {
  _deployfor = config.deployfor; // try to load deployfor context from branding file
}

if (config.deployfor === null) {
  _deployfor = "dev.localhost";
  // _deployfor = "ag-portal-dev";
  // _deployfor = 'ag-portal-live';
  // _deployfor = 'az52-dev';
}

const deployfor: string = _deployfor;

console.info(`globals.deployfor: ${deployfor}`);

export let registrationLink = "";
// export let registrationLink:string = 'https://aghedgedesk.com/drpapi/crs-test-registration.php';
// export let registrationLink:string = 'https://test.cropriskservices.com/Authentication/AgHedgeDesk';

export let permissionsurl = "";
export let returntourl = "";
export let serverip = "";

switch (deployfor) {
  case "stonex-portal":
    // serverip = 'aghedgedesk.com';
    serverip = "dev-api-161.aghedgedesk.com";
    break;
  case "ag-portal-live":
    // serverip = 'aghedgedesk.com';
    serverip = "dev-api-161.aghedgedesk.com";
    break;
  case "ag-portal-dev":
    // serverip = 'aghedgedesk.com';
    serverip = "dev-api-161.aghedgedesk.com";
    break;
  case "ag-preview":
    serverip = "dev-api-161.aghedgedesk.com";
    break;

  case "dev.localhost":
    serverip = "dev.localhost"; // must run on dev.localhost for cookies to work properly
    break;

  case "az52-dev":
    serverip = "dev-api-161.aghedgedesk.com/api-dev";
    break;

  case "az52-dev-bluehost":
    serverip = "api-m.aghedgedesk.com";
    break;

  case "az53":
    serverip = "dev-api-161.aghedgedesk.com";
    break;

  default: // must run on dev.localhost for cookies to work properly
    serverip = "dev.localhost";
    break;
}

export function getserverip_drpquote(): string {
  return serverip; // for now, just use same server always, but could change in future if desired
}

export function buildauthurl_drpquote(apifunc): string {
  let posturl;
  switch (deployfor) {
    case "ag-portal-live":
      // posturl = 'https://' + getserverip_drpquote() + '/drpapi/ag-portal-live-drpquote.php';
      posturl =
        "https://" + getserverip_drpquote() + "/drpapi/ag-portal-live.php";
      break;
    case "ag-portal-dev":
      posturl =
        "https://" +
        getserverip_drpquote() +
        "/api-dev/ag-portal-dev-drpquote.php";
      break;
    case "ag-preview":
      "https://" +
        getserverip_drpquote() +
        "/api-dev/ag-portal-app-preview.php";
      break;
    case "az53":
      posturl =
        "https://" +
        getserverip_drpquote() +
        // "/rabu/ag-portal-az53-drpquote-web.php";
        "/api-dev/apiforward-az53-bshaw-web.php";

      break;

    case "az52-dev":
      posturl = "https://" + getserverip_drpquote() + "/apiforward_dev.php";
      break;

    case "az52-dev-bluehost":
      posturl = "https://" + getserverip_drpquote() + "/apiforward_dev.php";
      break;

    case "dev.localhost":
      posturl = "http://" + getserverip_drpquote() + ":5001/drp/" + apifunc;
      break;

    default:
      posturl = "http://" + getserverip_drpquote() + ":5001/drp/" + apifunc;
      break;
  }
  return posturl;
}

export function buildurl_drpquote(apifunc): string {
  let posturl;
  switch (deployfor) {
    case "ag-portal-live":
      // posturl = 'https://' + getserverip_drpquote() + '/drpapi/ag-portal-live-drpquote.php';
      posturl =
        "https://" + getserverip_drpquote() + "/drpapi/ag-portal-live.php";
      break;
    case "ag-portal-dev":
      posturl =
        "https://" +
        getserverip_drpquote() +
        "/api-dev/ag-portal-dev-drpquote.php";
      break;
    case "az53":
      posturl =
        "https://" +
        getserverip_drpquote() +
        // "/rabu/ag-portal-az53-drpquote-web.php";
        "/api-dev/apiforward-az53-bshaw-web";
      break;
    case "az52-dev":
      posturl = "https://" + getserverip_drpquote() + "/apiforward_dev.php";
      break;
    case "ag-preview":
      posturl =
        "https://" +
        getserverip_drpquote() +
        "/api-dev/ag-portal-app-preview.php";
      break;
    case "az52-dev-bluehost":
      posturl = "https://" + getserverip_drpquote() + "/apiforward_dev.php";
      break;

    case "dev.localhost":
      posturl = "http://" + getserverip_drpquote() + ":5001/drp/" + apifunc;
      break;
    default:
      posturl = "http://" + getserverip_drpquote() + ":5001/drp/" + apifunc;
      break;
  }
  return posturl;
}

export function getserverip(): string {
  return serverip;
}

export function buildauthurl(apifunc): string {
  let posturl;

  switch (deployfor) {
    case "stonex-portal":
      posturl =
        "https://" + getserverip() + "/api-stonex/stonex-portal-live.php";
      break;
    case "ag-portal-live":
      posturl = "https://" + getserverip() + "/drpapi/ag-portal-live.php";
      break;
    case "ag-portal-dev":
      posturl = "https://" + getserverip() + "/api-dev/ag-portal-dev.php";
      break;

    case "az53":
      posturl =
        "https://" + getserverip() + "/api-dev/apiforward-az53-bshaw-web"; //rabu/apiforward-az53-web.php";
      break;

    case "az52-dev":
      posturl = "https://" + getserverip() + "/apiforward_dev.php";
      break;

    case "az52-dev-bluehost":
      posturl = "https://" + getserverip() + "/apiforward_dev.php";
      break;

    case "dev.localhost":
      posturl = "http://" + getserverip() + ":5001/drp/" + apifunc;
      break;
    case "ag-preview":
      posturl =
        "https://" + getserverip() + "/api-dev/ag-portal-app-preview.php";
      break;
    default:
      posturl = "http://" + getserverip() + ":5001/drp/" + apifunc;
      break;
  }
  return posturl;
}

export function buildurl(apifunc): string {
  let posturl;
  switch (deployfor) {
    case "stonex-portal":
      posturl =
        "https://" + getserverip() + "/api-stonex/stonex-portal-live.php";
      break;
    case "ag-portal-live":
      posturl = "https://" + getserverip() + "/drpapi/ag-portal-live.php";
      break;
    case "ag-portal-dev":
      posturl = "https://" + getserverip() + "/api-dev/ag-portal-dev.php";
      break;

    case "az53":
      posturl =
        "https://" + getserverip() + "/api-dev/apiforward-az53-bshaw-web"; //"/rabu/apiforward-az53-web.php";
      break;
    case "ag-preview":
      posturl =
        "https://" + getserverip() + "/api-dev/ag-portal-app-preview.php";
      break;
    case "az52-dev":
      posturl = "https://" + getserverip() + "/apiforward_dev.php";
      break;
    case "az52-dev-bluehost":
      posturl = "https://" + getserverip() + "/apiforward_dev.php";
      break;

    case "dev.localhost":
      posturl = "http://" + getserverip() + ":5001/drp/" + apifunc;
      break;
    default:
      posturl = "http://" + getserverip() + ":5001/drp/" + apifunc;
      break;
  }
  return posturl;
}

export function buildurl_get(apifunc: string, param = ""): string {
  let geturl;
  let body = "";
  if (param !== "") {
    body = "?target=" + param;
  }

  //if (params != {}) {
  //  for (const [key, value] of Object.entries(params)) {
  //    body = '&' + body + key + '=' + value
  //  }
  //}
  switch (deployfor) {
    case "az52-dev":
      geturl =
        "https://" +
        getserverip() +
        "/apiforward_dev.php?apifunc=" +
        apifunc +
        body;
      break;
    case "az52-dev-bluehost":
      geturl =
        "https://" +
        getserverip() +
        "/apiforward_dev.php?apifunc=" +
        apifunc +
        body;
      break;
    case "az61-dev":
      geturl =
        "https://" +
        getserverip() +
        "/ag-portal-dev-161-app.php?apifunc=" +
        apifunc +
        body;
      break;
    case "az53":
      geturl =
        "https://" +
        getserverip() +
        "/api-dev/apiforward-az53-bshaw-web" +
        // "/apiforward-az53.php?apifunc=" +
        apifunc +
        body;
      break;
    case "ag-preview":
      geturl =
        "https://" +
        getserverip() +
        "/api-dev/ag-portal-app-preview.php?apifunc=" +
        apifunc +
        body;
      break;
    case "dev.localhost":
      geturl = "http://" + getserverip() + ":5001/drp/" + apifunc + body;
      break;
    default:
      geturl =
        "https://" +
        getserverip() +
        "/api-dev/apiforward_dev.php?apifunc=" +
        apifunc +
        body;
      break;
  }

  return geturl;
}

export function checkdeploy(args, apifunc) {
  if (
    deployfor === "stonex-portal" ||
    deployfor === "dev.localhost" ||
    deployfor === "ag-portal-dev" ||
    deployfor === "ag-portal-live" ||
    deployfor === "az52-dev" ||
    deployfor === "ag-preview" ||
    deployfor === "az52-dev-bluehost" ||
    deployfor === "az53"
  ) {
    const args2 = Object.assign({}, args); // copy args
    args = {};
    let USING_MIDDLEWARE = import.meta.env.VITE_REACT_APP_USING_MIDDLEWARE;
    // let USING_MIDDLEWARE = true;

    // console.info("USING_MIDDLEWARE", USING_MIDDLEWARE);
    // console.info(deployfor);
    if (typeof USING_MIDDLEWARE === "string" && JSON.parse(USING_MIDDLEWARE)) {
      //START DEFAULT CHANGES
      args["apifunc"] = apifunc;
      args["payload"] = args2;
      //END DEFAULT CHANGES
    } else {
      //START DOCKER CHANGES
      //docker - directly hitting flask apifunc is not needed anymore
      // args["apifunc"] = apifunc;

      //docker - we dont want pass params with payload key - directly send them
      args = args2;
      //END DOCKER CHANGES
    }
  }

  return args;
}

const states_hash_reversed: any = branding.branding_states_hash_reversed;

const states_hash: any = branding.branding_states_hash; // try to load state list from branding file

let _src = branding.branding_src; // try to load branding state
if (typeof _src === "undefined") {
  _src = "None";
}

const src = _src;

let _default_state = branding.branding_default_state; // try to load branding state
if (typeof _default_state === "undefined") {
  _default_state = "Minnesota"; // use 'Minnesota' by default if no branding state declared
}

const default_state = _default_state;

const use_domain_objects_lgm = true;
const use_domain_objects_drp = true;
const use_domain_objects_lrp = true;
export {
  states_hash,
  states_hash_reversed,
  default_state,
  src,
  deployfor,
  use_domain_objects_lgm,
  use_domain_objects_drp,
  use_domain_objects_lrp,
  // log_options,
};
