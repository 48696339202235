import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/esm/Collapse";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { resetDownloadHedgeMAP } from "../features/hedgeMAPsSlice";
import { downloadHedgeMAP } from "../features/hedgeMAPsActions";
import "./HedgeMAPListing.scss";

const HedgeMAPListing = ({ listing }) => {
  const dispatch = useAppDispatch();

  const { message, isLoading, fileName, isError } = useAppSelector(
    (state) => state.hedgeMAPs.downloadHedgeMAP,
  );
  const [agencyExpanded, setAgencyExpanded] = useState(false);
  const [dateExpanded, setDateExpanded] = useState(
    Object.fromEntries(
      listing.contents.map((date_folder) => [
        date_folder.date_folder_display_name,
        false,
      ]),
    ),
  );

  const [hedgeMAPTypeExpanded, setHedgeMAPTypeExpanded] = useState(
    Object.fromEntries(
      listing.contents.map((date_folder) => [
        date_folder.date_folder_display_name,
        Object.fromEntries(
          date_folder.date_folder_contents.map((insurance_type) => [
            insurance_type.insurance_type,
            false,
          ]),
        ),
      ]),
    ),
  );

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        dispatch(resetDownloadHedgeMAP());
      }, 2000);
    }
  }, [isError, dispatch]);

  return (
    <div
      className="listing-component "
      style={{ minWidth: 600 }}
      key={listing.name}
    >
      {/* Agency */}
      <span
        onClick={() => {
          setAgencyExpanded(!agencyExpanded);
        }}
        className="maroon-border-left list-group-item rounded-0 list-group-link"
      >
        <span id="chevron-arrow-right"></span>
        {listing.name}
      </span>
      <Collapse in={agencyExpanded}>
        <div className="list-group">
          {/* Date Folder  */}

          {listing.contents.map((date_folder) => (
            <div className="indent" key={date_folder.date_folder_display_name}>
              <span
                onClick={() => {
                  let newObj = { ...dateExpanded };
                  newObj[date_folder.date_folder_display_name] =
                    !dateExpanded[date_folder.date_folder_display_name];
                  setDateExpanded(newObj);
                }}
                className="list-group-item maroon-border-left rounded-0 list-group-link"
              >
                <span id="chevron-arrow-right"></span>
                {date_folder.date_folder_display_name}
              </span>
              <Collapse in={dateExpanded[date_folder.date_folder_display_name]}>
                <div className="list-group">
                  {/* Insurance Types  */}
                  {date_folder.date_folder_contents.map((insurance_type) => (
                    <div className="indent" key={insurance_type.insurance_type}>
                      <span
                        className="list-group-item maroon-border-left rounded-0 list-group-link"
                        onClick={() => {
                          let newObj = { ...hedgeMAPTypeExpanded };
                          newObj[date_folder.date_folder_display_name][
                            insurance_type.insurance_type
                          ] =
                            !hedgeMAPTypeExpanded[
                              date_folder.date_folder_display_name
                            ][insurance_type.insurance_type];
                          setHedgeMAPTypeExpanded(newObj);
                        }}
                      >
                        <span id="chevron-arrow-right"></span>
                        {insurance_type.insurance_type}
                      </span>
                      <Collapse
                        in={
                          hedgeMAPTypeExpanded[
                            date_folder.date_folder_display_name
                          ][insurance_type.insurance_type]
                        }
                      >
                        <div className="list-group indent">
                          {/* HedgeMAP File Links  */}

                          {insurance_type.hedgemap_files.map(
                            (hedgemap_file) => (
                              <div
                                className="list-group-item maroon-border-left rounded-0"
                                key={hedgemap_file.name}
                              >
                                <span className="align-middle">
                                  {hedgemap_file.name}
                                </span>
                                {isLoading &&
                                hedgemap_file.name === fileName ? (
                                  <div style={{ float: "right" }}>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span> {message} </span>
                                  </div>
                                ) : (
                                  <button
                                    onClick={() =>
                                      dispatch(
                                        downloadHedgeMAP([
                                          listing.name,
                                          date_folder.date_folder_name,
                                          insurance_type.insurance_type,
                                          hedgemap_file.name,
                                        ]),
                                      )
                                    }
                                    className={
                                      hedgemap_file.name === fileName && isError
                                        ? "btn btn-danger"
                                        : "btn btn-success"
                                    }
                                    style={{ float: "right" }}
                                    disabled={
                                      hedgemap_file.name === fileName && isError
                                    }
                                  >
                                    {hedgemap_file.name === fileName && isError
                                      ? "Error. Try again later."
                                      : "Download"}
                                  </button>
                                )}
                              </div>
                            ),
                          )}
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};
export default HedgeMAPListing;
