import hedgeMAPService from "./hedgeMAPsService";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getHedgeMAPListing = createAsyncThunk(
  "drp/sendGetHedgeMAPListingRequest",
  async (_: void, thunkAPI) => {
    try {
      const response = await hedgeMAPService.sendGetHedgeMAPListingRequest();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch hedgeMAPs. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch hedgeMAPs. Please try again later.",
      );
    }
  },
);

export const downloadHedgeMAP = createAsyncThunk(
  "drp/downloadHedgeMAP",
  async (data: any, thunkAPI) => {
    try {
      const response = await hedgeMAPService.sendDownloadHedgeMAPRequest(data);
      const payload = await response;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to download hedgeMAP. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to download hedgeMAP. Please try again later.",
      );
    }
  },
);
