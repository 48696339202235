// import { updateDownloadPercentForPodcast } from "../features/Podcasts/podcastsSlice";
import { buildauthurl, buildurl_drpquote, checkdeploy } from "../../globals";
import defaultInstance from "./axiosConfig";

export const sendAuthRequest = async (endpoint: string, payload: object) => {
  const posturl = buildauthurl(endpoint);
  const args = checkdeploy(payload, endpoint);
  console.log(posturl, args);

  const response = await defaultInstance.post(
    posturl,
    args,

    {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    },
  );

  return response;
};

export const sendDRPRequest = async (endpoint: string, payload: object) => {
  const posturl = buildurl_drpquote(endpoint);
  const args = checkdeploy(payload, endpoint);

  let response = await defaultInstance.post(posturl, args);
  return response;
};

export const sendLGMRequest = async (endpoint: string, payload: object) => {
  const posturl = buildurl_drpquote(endpoint);
  const args = checkdeploy(payload, endpoint);

  let response = await defaultInstance.post(posturl, args);

  return response;
};

export const sendLRPRequest = async (endpoint: string, payload: object) => {
  const posturl = buildurl_drpquote(endpoint);
  const args = checkdeploy(payload, endpoint);

  let response = await defaultInstance.post(posturl, args);

  return response;
};
export const sendResourcesRequest = async (
  endpoint: string,
  payload: object,
) => {
  const posturl = buildurl_drpquote(endpoint);
  const args = checkdeploy(payload, endpoint);

  let response = await defaultInstance.post(posturl, args, {
    responseType: "blob",
  });

  return response;
};

export const sendViewHedgeMAPsRequest = async (
  endpoint: string,
  payload: object,
) => {
  const posturl = buildurl_drpquote(endpoint);
  const args = checkdeploy(payload, endpoint);
  //responseType blob
  let response = await defaultInstance.post(posturl, args);

  return response;
};

export const sendDownloadHedgeMAPsRequest = async (
  endpoint: string,
  payload: object,
) => {
  const posturl = buildurl_drpquote(endpoint);
  const args = checkdeploy(payload, endpoint);

  let response = await defaultInstance.post(posturl, args, {
    responseType: "blob",
  });

  return response;
};
export const sendDownloadPodcastRequest = async (
  endpoint: string,
  payload: object,
  dispatch: any,
) => {
  const posturl = buildurl_drpquote(endpoint);
  const args = checkdeploy(payload, endpoint);

  let response = await defaultInstance.post(posturl, args, {
    responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      // let percentCompleted = Math.round(
      //   (progressEvent.loaded * 100) / progressEvent.total
      // );
      // console.info("percentCompleted", percentCompleted);
      //   dispatch(
      //     updateDownloadPercentForPodcast({
      //       name: payload["name"],
      //       percent: percentCompleted,
      //     })
      //   );
    },
  });

  return response;
};
export const sendAdminRequest = async (endpoint: string, payload: object) => {
  const posturl = buildurl_drpquote(endpoint);
  const args = checkdeploy(payload, endpoint);

  let response = await defaultInstance.post(posturl, args);

  return response;
};
